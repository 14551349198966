import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    employeeLists: [],
}

export const employeeSlice = createSlice({
    name: 'employees',
    initialState,
    reducers: {
        setEmployee: (_, action) => action.payload,
    },
})

export const { setEmployee } = employeeSlice.actions

export default employeeSlice.reducer

import { apiSignIn, apiSignUp } from 'services/AuthService'
import { initialState, setUser } from 'store/auth/userSlice'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import { useDispatch, useSelector } from 'react-redux'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import appConfig from 'configs/app.config'
import { useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import useQuery from './useQuery'
function useAuth() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const query = useQuery()
    const { token, signedIn } = useSelector((state) => state.auth.session)
    const signIn = async (values) => {
        try {
            const loginDetails = { ...values, clientId: 'web' }
            const resp = await apiSignIn(loginDetails)
            if (resp.data.result) {
                const token = resp.data.result.accessToken
                localStorage.setItem('tempToken', token)
                const avatar = resp.data.result.avatar
                const userId = resp.data.result.userId
                const decodedToken = decodeToken(token, values.email)
                dispatch(
                    onSignInSuccess({
                        avatar,
                        token,
                        userId,
                        roles: decodedToken?.authority,
                    })
                )
                if (token) {
                    dispatch(
                        setUser(
                            { ...decodedToken, avatar } || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: values.email,
                            }
                        )
                    )
                    localStorage.setItem(
                        'tempUser',
                        JSON.stringify({
                            ...decodedToken,
                            avatar,
                        })
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                setTimeout(() => {
                    navigate(
                        redirectUrl
                            ? redirectUrl
                            : appConfig.authenticatedEntryPath
                    )
                }, 1000)
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            const data = errors?.response?.data
            const err = data?.responseException?.exceptionMessage?.errors
            return {
                status: 'failed',
                message: extractErrorMessage(err),
            }
        }
    }
    function extractErrorMessage(json) {
        let msg = ''
        for (const key in json) {
            let item = json[key]
            for (let i = 0; i < item?.length; i++) {
                msg = item[i]
                if (msg === undefined) {
                    return undefined
                }
            }
        }
        return msg
    }
    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                const { token } = resp.data
                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }
    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }
    const signOut = async () => {
        handleSignOut()
    }
    function decodeToken(token, email) {
        const decodedToken = jwt_decode(token)
        const authority =
            typeof decodedToken?.role === 'string'
                ? [decodedToken?.role]
                : [...decodedToken?.role]
        return {
            avatar: '',
            userName: `${decodedToken?.LastName}, ${decodedToken?.firstName}`,
            email: email,
            authority,
            userId: decodedToken?.sub,
        }
    }
    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
        handleSignOut,
    }
}
export default useAuth

import { Notification, toast } from 'components/ui'

export default function openNotification(type, message, title) {
    toast.push(
        <Notification title={title} type={type}>
            {message}
        </Notification>
    )
}

export function openSuccessNotification(message) {
    openNotification('success', message, 'Success')
}

export function openErrorNotification(message) {
    openNotification('danger', message, 'Error')
}

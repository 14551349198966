import { useDispatch, useSelector } from 'react-redux'
import { ConfigProvider } from 'components/ui'
import useDarkMode from 'utils/hooks/useDarkMode'
import { themeConfig } from 'configs/theme.config'
import { useEffect } from 'react'
import { getThemeCustomization } from 'store/theme/themeSlice'
const Theme = (props) => {
    const theme = useSelector((state) => state.theme.theme)
    const locale = useSelector((state) => state.locale.currentLang)
    const [isDark] = useDarkMode()
    const currentTheme = {
        mode: isDark ? 'dark' : 'light',
        ...themeConfig,
        ...theme,
        ...{ locale },
    }

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getThemeCustomization())
    }, [])
    return (
        <ConfigProvider value={currentTheme}>{props.children}</ConfigProvider>
    )
}
export default Theme

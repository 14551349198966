import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useConfig } from '../ConfigProvider'
import { useForm } from '../Form/context'
import { useInputGroup } from '../InputGroup/context'
import { CONTROL_SIZES, SIZES } from '../utils/constant'
import Spinner from '../Spinner'
import { useSelector } from 'react-redux'

const Button = React.forwardRef((props, ref) => {
    const {
        children,
        size,
        color,
        shape,
        variant,
        block,
        icon,
        className,
        disabled,
        loading,
        active,
        danger,
        ...rest
    } = props
    const { controlSize } = useConfig()
    const formControlSize = useForm()?.size
    const inputGroupSize = useInputGroup()?.size
    const defaultClass = 'button  hover:opacity-80'
    const sizeIconClass = 'inline-flex items-center justify-center'

    const buttonSize = size || inputGroupSize || formControlSize || controlSize

    const {
        primaryTextColor,
        secondaryTextColor,
        primaryBackgroundColor,
        secondaryBackgroundColor,
    } = useSelector((state) => state.theme.theme)

    const getButtonSize = () => {
        let sizeClass = ''
        switch (buttonSize) {
            case SIZES.LG:
                sizeClass = classNames(
                    `h-${CONTROL_SIZES.lg}`,
                    icon && !children
                        ? `w-${CONTROL_SIZES.lg} ${sizeIconClass} text-2xl`
                        : 'px-8 py-2 text-base'
                )
                break
            case SIZES.SM:
                sizeClass = classNames(
                    `h-${CONTROL_SIZES.sm}`,
                    icon && !children
                        ? `w-${CONTROL_SIZES.sm} ${sizeIconClass} text-lg`
                        : 'px-3 py-2 text-sm'
                )
                break
            case SIZES.XS:
                sizeClass = classNames(
                    `h-${CONTROL_SIZES.xs}`,
                    icon && !children
                        ? `w-${CONTROL_SIZES.xs} ${sizeIconClass} text-base`
                        : 'px-3 py-1 text-xs'
                )
                break
            default:
                sizeClass = classNames(
                    `h-${CONTROL_SIZES.md}`,
                    icon && !children
                        ? `w-${CONTROL_SIZES.md} ${sizeIconClass} text-xl`
                        : 'px-8 py-2'
                )
                break
        }
        return sizeClass
    }

    const disabledClass = 'button opacity-50 cursor-not-allowed'

    const classes = classNames(
        disabled || loading ? disabledClass : defaultClass,
        `radius-${shape}`,
        getButtonSize(),
        className,
        block ? 'w-full' : ''
    )

    const primaryBtnStyles = {
        style: {
            color: primaryTextColor,
            backgroundColor: primaryBackgroundColor,
        },
        className: classes,
    }

    const secondaryBtnStyles = {
        style: {
            color: secondaryTextColor,
            backgroundColor: secondaryBackgroundColor,
        },
        className: classes,
    }

    const tertiaryBtnStyles = {
        className: classNames(
            classes,
            `bg-transparent border border-transparent text-gray-600 dark:text-gray-100`
        ),
    }

    const defaultBtnStyles = {
        className: classNames(
            classes,
            `bg-white border border-gray-300 dark:bg-gray-700 dark:border-gray-700 text-gray-600 dark:text-gray-100`
        ),
    }

    const getButtonStyles = () => {
        switch (variant) {
            case 'secondary':
                return secondaryBtnStyles
            case 'primary':
                return primaryBtnStyles
            case 'plain':
                return tertiaryBtnStyles
            default:
                return defaultBtnStyles
        }
    }

    const buttonStyles = getButtonStyles()

    const handleClick = (e) => {
        const { onClick } = props
        if (disabled || loading) {
            e.preventDefault()
            return
        }
        onClick?.(e)
    }

    const renderChildren = () => {
        if (loading && children) {
            return (
                <span className="flex items-center justify-center">
                    <Spinner enableTheme={false} className="mr-1" />
                    {children}
                </span>
            )
        }

        if (icon && !children && loading) {
            return <Spinner enableTheme={false} />
        }

        if (icon && !children && !loading) {
            return <>{icon}</>
        }

        if (icon && children && !loading) {
            return (
                <span className="flex items-center justify-center">
                    <span className="text-lg">{icon}</span>
                    <span className="ltr:ml-1 rtl:mr-1">{children}</span>
                </span>
            )
        }

        return <>{children}</>
    }

    return (
        <button ref={ref} {...buttonStyles} {...rest} onClick={handleClick}>
            {renderChildren()}
        </button>
    )
})

Button.propTypes = {
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    block: PropTypes.bool,
    shape: PropTypes.oneOf(['round', 'circle', 'none']),
    className: PropTypes.string,
    size: PropTypes.oneOf([SIZES.LG, SIZES.SM, SIZES.XS, SIZES.MD]),
    color: PropTypes.string,
    variant: PropTypes.oneOf(['secondary', 'primary', 'plain', 'default']),
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    active: PropTypes.bool,
}

Button.defaultProps = {
    variant: 'default',
    shape: 'round',
    active: false,
    loading: false,
    disabled: false,
    color: '',
}

export default Button

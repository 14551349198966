import {components} from 'react-select'
import {Badge} from "../ui";

const {Control} = components
export default function CustomControl({children, ...props}) {
    const selected = props.getValue()[0]
    return (
        <Control {...props}>
            {selected && (
                <Badge
                    className="ltr:ml-4 rtl:mr-4"
                    innerClass={selected.color}
                />
            )}
            {children}
        </Control>
    )
}

import ApiService from './ApiService'
export async function apiGetCollection(data) {
    return ApiService.fetchData({
        url: '/collections',
        method: 'post',
        data,
    })
}
export async function apiGetCollectionLink(data) {
    return ApiService.fetchData({
        url: '/collections/link',
        method: 'post',
        data,
    })
}
export async function apiGetAllCollection() {
    return ApiService.fetchData({
        url: `/collections`,
        method: 'get',
    })
}

export async function apiGetCollectionScreens() {
    return ApiService.fetchData({
        url: `/collections/screens`,
        method: 'get',
    })
}

export async function apiRunAISearch(payload) {
    const parsedQueryParams = {
        ...payload,
    }
    return ApiService.fetchData({
        url: `/query/sql?${new URLSearchParams(parsedQueryParams).toString()}`,
        method: 'get',
    })
}

import { Button, Drawer } from '../ui'
import PropTypes from 'prop-types'

const DrawerFooter = ({ onSaveClick, onCancel, loading }) => {
    return (
        <div className="text-right w-full">
            <Button
                size="sm"
                className="mr-2"
                onClick={onCancel}
                disabled={loading}
            >
                Cancel
            </Button>
            <Button
                size="sm"
                variant="secondary"
                onClick={onSaveClick}
                disabled={loading}
            >
                Save
            </Button>
        </div>
    )
}

export default function SideDrawer(props) {
    const { open, onDrawerClose } = props
    return (
        <Drawer
            isOpen={open}
            title={props?.title}
            onClose={onDrawerClose}
            onRequestClose={onDrawerClose}
            closable={true}
            bodyClass="p-0"
            footer={
                <DrawerFooter
                    loading={props.loading}
                    onCancel={onDrawerClose}
                    onSaveClick={props?.onSubmit}
                />
            }
        >
            {props.children}
        </Drawer>
    )
}

SideDrawer.defaultProps = {
    open: false,
}

SideDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    onDrawerClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    title: PropTypes.string,
}

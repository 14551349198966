import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const MenuItem = React.forwardRef((props, ref) => {
    const {
        asElement: Component,
        children,
        className,
        disabled,
        eventKey,
        isActive,
        menuItemHeight,
        onSelect,
        style,
        variant,
        disableHover = false,
        ...rest
    } = props

    const [isHovered, setIsHovered] = useState(false)
    const { secondaryBackgroundColor } = useSelector((state) => state.theme.theme)

    const menuItemActiveClass = `menu-item-active !text-gray-900 `
    const menuItemHoverClass = `menu-item-hoverable hover:text-gray-900 `
    const disabledClass = 'menu-item-disabled'
    const menuItemClass = classNames(
        'menu-item',
        `menu-item-${variant}`,
        isActive && menuItemActiveClass,
        disabled && disabledClass,
        !disabled && !disableHover && menuItemHoverClass,
        className
    )

    const hanldeOnClick = (e) => {
        if (onSelect) {
            onSelect(eventKey, e)
        }
    }

    return (
        <Component
            ref={ref}
            className={menuItemClass}
            style={{
                height: `${menuItemHeight}px`,
                backgroundColor:
                    isHovered || isActive ? secondaryBackgroundColor : '',
                ...style,
            }}
            onClick={hanldeOnClick}
            onMouseOver={() => setIsHovered(true)}
            onMouseOut={() => setIsHovered(false)}
            {...rest}
        >
            {children}
        </Component>
    )
})

MenuItem.defaultProps = {
    asElement: 'div',
    menuItemHeight: 35,
    variant: 'light',
}

MenuItem.propTypes = {
    asElement: PropTypes.string,
    menuItemHeight: PropTypes.number,
    disabled: PropTypes.bool,
    isActive: PropTypes.bool,
    eventKey: PropTypes.string,
    variant: PropTypes.oneOf(['light', 'dark', 'themed', 'transparent']),
}

export default MenuItem

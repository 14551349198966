import { Button, Dialog } from 'components/ui'
import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'

const timeout = 900_000
const promptBeforeIdle = 60_000
const SessionTimeout = ({ signOut, authenticated }) => {
    const [state, setState] = useState('Active')
    const [remaining, setRemaining] = useState(timeout)
    const [open, setOpen] = useState(false)

    const onIdle = () => {
        setState('Idle')
        setOpen(false)
        signOut()
    }

    const onActive = () => {
        setState('Active')
        setOpen(false)
    }

    const onPrompt = () => {
        setState('Prompted')
        setOpen(true)
    }

    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: 500,
        disabled: !authenticated,
    })

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }
    })
    const handleStillHere = () => {
        activate()
        setOpen(false)
    }
    const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)
    const seconds = timeTillPrompt > 1 ? 'seconds' : 'second'
    return (
        <div>
            {open && (
                <Dialog
                    style={{
                        content: {
                            marginTop: 250,
                        },
                    }}
                    contentClassName="pb-0 px-0"
                    isOpen={open}
                    onRequestClose={() => {
                        handleStillHere()
                        setOpen(false)
                    }}
                    width={400}
                    onClose={() => {
                        handleStillHere()
                        setOpen(false)
                    }}
                >
                    <div className="px-3 pb-6">
                        <div className="flex flex-col items-start justify-start">
                            <h3 className="font-semibold">
                                Are you still there?
                            </h3>
                            <p>Logging out in {remaining} seconds</p>
                        </div>
                        <div className="flex justify-end gap-4 pt-10">
                            <Button
                                size="sm"
                                className="ltr:mr-2 rtl:ml-2"
                                onClick={() => {
                                    signOut()
                                    setOpen(false)
                                }}
                            >
                                Logout Now
                            </Button>
                            <Button
                                size="sm"
                                variant="secondary"
                                onClick={handleStillHere}
                            >
                                Stay Logged In
                            </Button>
                        </div>
                    </div>
                </Dialog>
            )}
        </div>
    )
}

export default SessionTimeout

import {createSlice} from '@reduxjs/toolkit'

export const initialState = {
    currentRouteKey: '',
    showAlert: false,
    notification: {}
}

export const commonSlice = createSlice({
    name: 'base/common',
    initialState,
    reducers: {
        setCurrentRouteKey: (state, action) => {
            state.currentRouteKey = action.payload
        },
        showSuccessAlertNotification: (state, action) => {
            state.notification = {
                type: 'success',
                showIcon: false,
                message: action.payload
            }
            state.showAlert = true
        },
        showErrorAlertNotification: (state, action) => {
            state.notification = {
                type: 'danger',
                showIcon: false,
                message: action.payload
            }
            state.showAlert = true
        },
        showWarningAlertNotification: (state, action) => {
            state.notification = {
                type: 'warning',
                showIcon: true,
                message: action.payload
            }
            state.showAlert = true
        },
        showInfoAlertNotification: (state, action) => {
            state.notification = {
                type: 'info',
                showIcon: true,
                message: action.payload
            }
            state.showAlert = true
        },
        dismissAlertNotification: (state, action) => {
            state.showAlert = false
        },
    },
})

export const {
    setCurrentRouteKey,
    showSuccessAlertNotification,
    showErrorAlertNotification,
    showInfoAlertNotification,
    dismissAlertNotification,
    showWarningAlertNotification
} = commonSlice.actions

export default commonSlice.reducer

import {Badge} from "../ui";
import {HiCheck} from 'react-icons/hi'

export default function CustomSelectOption({innerProps, label, data, isSelected}){
    return (
        <div
            className={`flex items-center justify-between p-2 cursor-pointer ${
                isSelected
                    ? 'bg-gray-100 dark:bg-gray-500'
                    : 'hover:bg-gray-50 dark:hover:bg-gray-600'
            }`}
            {...innerProps}
        >
            <div className="flex items-center gap-2">
                <Badge innerClass={data.color}/>
                <span>{label}</span>
            </div>
            {isSelected && <HiCheck className="text-emerald-500 text-xl"/>}
        </div>
    )
}

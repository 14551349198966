import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
    name: 'auth/session',
    initialState: {
        avatar: '',
        token: '',
        signedIn: false,
        userId: '',
        roles: [],
    },
    reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true
            state.token = action.payload.token
            state.avatar = action.payload.avatar
            state.userId = action.payload.userId
            state.roles = action.payload?.roles
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false
            state.token = ''
            state.avatar = ''
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
    },
})

export const { onSignInSuccess, onSignOutSuccess, setToken } =
    sessionSlice.actions

export default sessionSlice.reducer
